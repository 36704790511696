<template>
  <div class="Project">
    <ContainerCard :locationMsg="locationMsg">
      <!-- 搜索 -->
      <!-- <Search
        v-if="searchMsg.type.length > 0"
        :searchMsg="searchMsg"
        @searchKey="searchHandle"
      ></Search> -->
      <div class="search">
        <div class="title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="title-size">项目库</span>
        </div>
        <div class="search-box">
          <span
            ><img
              src="../../assets/images/政策公告-搜索.png"
              class="search-icon"
          /></span>
          <input
            class="input"
            v-model="input"
            placeholder="请输入您要搜索的内容"
          />
          <div class="search-button" @click="searchHandle">
            <span class="button-word">搜索</span>
          </div>
        </div>
      </div>
      <Technology style="width: 1260px" @techHandle="techHandle"></Technology>
      <!-- 成果库列表 -->
      <div class="achievement-container-wrapper" v-empty="List">
        <div
          v-for="(pro, index) in List"
          :class="[
            'achievement-container',
            index % 2 == 0
              ? 'achievement-container-left'
              : 'achievement-container-right',
          ]"
          :key="index"
          @click="toDetail(pro.billid)"
        >
          <div class="achidevments">
            <div class="top-achi">
              <!-- img -->
              <el-image class="img" :src="httpConfig.baseURL+pro.photo" alt="" v-if="pro.photo" />
              <div v-if="!pro.photo" class="no-imgbox">
              <el-image class="no-img" :src=" defaultImg" alt="" />
            </div>
              <!-- word/logo -->
              <div class="word-container">
                <span class="word">{{ pro.project_name }}</span>
                <!-- <div class="infoGroup" v-if="pro.contact">
                  <span class="contact">项目联系人</span>
                  <span class="itemLabel">{{ pro.contact }}</span>
                </div> -->
                <div class="miniword-container">
                  <div style="margin:10px 0px 10px 0px">
                  <span class="introduction">甲方建设主体</span>
                  <!-- 这个建设主体后端还没更新给我！！ -->
                  <!-- <span v-if="!pro.enterprise_name" class="no-word">暂无</span> -->
                   <span
                    class="mini-word"
                    style="line-height: 26px"
                    v-if="pro.enterprise_name"
                  >{{ pro.enterprise_name || '暂无' }}</span>
                  </div>
                  <div>
                  <span class="introduction">乙方建设主体</span>
                  <!-- 这个建设主体后端还没更新给我！！ -->
                  <!-- <span v-if="!pro.enterprise_name" class="no-word">暂无</span> -->
                   <span
                    class="mini-word"
                    style="line-height: 26px"
                    v-if="pro.enterprise_name"
                  >{{ pro.school_name || '暂无'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- line -->
            <!-- <div class="inline"></div> -->
            <!-- miniword -->
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <Pagination
        class="center"
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Search from "../../components/SixLibraries/Search.vue";
import Pagination from "../../components/Pagination.vue";
import { getProjects } from "../../api/SixLibraries/Project/Project.js";
import ContainerCard from "../../components/newContainerCard.vue";
import Technology from "../../components/Info/Technology.vue";
import { getPlainText } from "../../common/utils/tool";
import { mapGetters } from "vuex";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
import httpConfig from "../../common/utils/config";
export default {
  name: "project",
  computed: {
    ...mapGetters(["techfieldList"]),
  },

  data() {
    return {
      httpConfig,
      defaultImg,
      searchMsg: {
        title: "技术领域",
        type: [],
      },
      input: "",
      locationMsg: [],
      List: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      params: {
        tech_field: "", //技术领域
        keyword: "",
        orderstr: "make_date", //click_num
        pageNo: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    let techList = [];
    techList.push({
      label: "全部",
      value: "all",
    });

    Object.keys(this.techfieldList).forEach((item) => {
      techList.push({
        label: item,
        value: this.techfieldList[item],
      });
    });
    this.searchMsg.type = techList;
  },
  methods: {
    async getData() {
      let that = this;
      that.fullscreenLoading = true;
      let res = await getProjects(that.params);
      that.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.techList = [];
        tmp.memo = getPlainText(getPlainText(item.memo));
        Object.keys(that.techfieldList).forEach(function (key) {
          if (
            tmp?.tech_field &&
            tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
          ) {
            tmp.techList.push(key);
          }
        });
        return tmp;
      });
      console.log("项目库数据:",this.List)
      this.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },
    searchHandle(val) {
      this.params.keyword = this.input;
      // this.params.tech_field = val === "all" ? "" : val;
      this.getData();
    },
    techHandle(val) {
      this.params.keyword = this.input;
      this.params.tech_field = val === "all" ? "" : val;
      console.log("val:",val)
      this.getData(); 
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({
        path: "/project/projectDetails",
        query: { id: id },
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: { Location, Search, Pagination, ContainerCard, Technology },
};
</script>
<style scoped lang="scss">
.center {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 118px;
}

// 列表容器样式
@mixin setHeightAndWidth($w, $h) {
  width: $w;
  height: $h;
}
@mixin setFont($size, $lih, $col) {
  font-size: $size;
  font-family: Medium;
  line-height: $lih;
  color: $col;
}
.search {
  height: 86px;
  width: 1260px;
  box-sizing: border-box;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    img {
      @include setHeightAndWidth(8px, 10px);
      margin-right: 8px;
    }
    .title-size{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
  }
  .search-box {
    box-sizing: border-box;
    width: 696px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    .input {
      height: 34px;
      width: 572px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: Regular;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
}

.achievement-container-wrapper {
  // width: 100%;
  // height: 100%;
  width: 1260px;
  height: 456px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr; /// 二列二等分
  grid-gap: 0 0; // 行间距，列间距
  .achievement-container-left {
    border-right: 1px solid #f1f1f1;
    padding-left: 0px;
    &:hover {
      padding-left: 30px;
    }
  }
  .achievement-container-right {
    padding-left: 30px !important;
  }
  .achievement-container {
    // width: 408px;
    height: 156px;
    width: 630px;
    box-sizing: border-box;
    padding: 30px 30px 30px 0px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    // &:nth-child(odd) {
    //   border-right: 0px solid #f1f1f1;
    // }
    // &:nth-child(even) {
    //   border-left: 0px solid #f1f1f1;
    //   padding-left: 0px;
    //   &:hover {
    //     padding-left: 30px;
    //   }
    // }
    &:hover {
      box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
      z-index: 999;
    }
    .achidevments {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .top-achi {
        display: flex;
        flex: row;
        width: 100%;
        height: 100%;
        .img {
          width: 150px;
          height: 90px;
          border-radius: 6px;
          margin-right: 20px;
        }
        .no-imgbox {
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
        .word-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin-left: 20px;
          .word {
            font-size: 16px;
            font-family: Medium;
            font-weight: 500;
            color: #101010;
            line-height: 24px;
            white-space: nowrap;
          }
          .infoGroup {
            margin: 3px 0px 3px 0px;
            // background: red;
            display: flex;
            // padding: 20px;
            .itemLabel {
              width: 64px;
              white-space: nowrap;
              font-size: 12px;
              font-family: Regular;
              font-weight: 400;
              color: #101010;
              line-height: 18px;
              padding-left: 8px;
            }

            // .text {
            //   // width: 208px;
            //   // line-height: 20px;
            // }
          }
          .contact {
            // background: #2885ff;
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #8896a7;
            line-height: 18px;
          }

          .log-container {
            // margin-left: 20px;
            // border-radius: 8px;
            // padding: 7px 10px;
            // font-size: 14px;
            // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            // font-weight: 400;
            // color: #181515;
            // background: #3e9cff;
            // border-radius: 8px;
          }

          .profession {
            margin-top: 16px;
          }
        }
      }
      .inline {
        width: 100%;
        height: 1px;
        background: #eaeaea;
      }
      // .miniword-container {
      //   // align-self: flex-start;
      //   width: 332px;
      //   margin-top: 16px;
      //   margin-bottom: 16px;
      //   // background: red;
      //   display: flex;
      //   .mini-word {
      //     padding-left:20px ;
      //     // background: red;
      //     font-size: 12px;
      //     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      //     font-weight: 400;
      //     color: #404040;
      //     line-height: 18px;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     display: -webkit-box;
      //     -webkit-line-clamp: 1;
      //     -webkit-box-orient: vertical;
      //   }
      // }
      .introduction {
        color: #8896a7;
        font-size: 12px;
        // left: 0;
        // top: 29px;
      }
      .miniword-container {
        // padding-top: 12px;
        width: 332px;
        // align-self: flex-start;
        align-items: center;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #404040;

        // margin-top: 16px;
        .mini-word {
          margin-top: -19px;
          margin-left: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          // -moz-binding:url('ellipsis.xml#ellipsis');
          // white-space:nowrap;
        }
        .no-word{
          margin-left: 20px;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #404040;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
}
</style>

